<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("newOutgoing.addNewTransfer") }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`addNewContactsModal.close`)"
            v-on:click="$emit('update:show', false)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="text-start m-2 custom-input">
            <div class="mb-3 whitebg-input" v-if="id === null">
              <label class="form-label bold-12 text-gray">{{
                $t("newOutgoing.sendFrom")
              }}</label>
              <v-select
                :options="warehouses"
                v-model="newOutcome.warehouse_id"
                label="name"
                :placeholder="$t(`finance.notSelected`)"
                :reduce="(c) => c.id"
                disabled
              />
            </div>
            <div class="mb-3 whitebg-input">
              <label class="form-label bold-12 text-gray">{{
                $t("newOutgoing.sendType")
              }}</label>
              <v-select
                :options="productTypeOptions"
                v-model="productType"
                label="name"
                :placeholder="$t(`finance.notSelected`)"
                :reduce="(c) => c.id"
              />
            </div>
            <div class="mb-3 whitebg-input" v-if="id === null">
              <label class="form-label bold-12 text-gray">{{
                $t("newOutgoing.sendTo")
              }}</label>
              <v-select
                :options="
                  warehouses.filter(
                    (e) =>
                      e.id !== newOutcome.warehouse_id &&
                      ((productType === 1 && e.type !== 2) ||
                        (productType === 2 && e.type !== 3))
                  )
                "
                v-model="newOutcome.warehouse_income_id"
                label="name"
                :placeholder="$t(`newOutgoing.selectAWarehouse`)"
                :reduce="(c) => c.id"
              />
            </div>
            <div
              class="row custom-input whitebg-input"
              v-for="p in addProducts"
              :key="p.id"
            >
              <div class="col-3">
                <label class="form-label bold-12 text-gray">{{
                  $t("ingredients.quantity")
                }}</label>
                <input
                  type="number"
                  v-model="p.quantity"
                  class="form-control"
                  min="1"
                />
              </div>
              <div class="col-9">
                <div class="mb-3">
                  <label class="form-label bold-12 text-gray">{{
                    $t("chart1.product")
                  }}</label>
                  <v-select
                    v-model:options="p.products"
                    v-on:search="(s, k) => fetchProducts(s, k, p)"
                    v-model="p.id"
                    :filterable="false"
                    :placeholder="$t(`copyProducts.selectProduct`)"
                    :reduce="(c) => c.variant_id"
                    :getOptionKey="(c) => c.variant_id"
                    v-on:option:selected="(e) => changeProducts(e.variant_id)"
                    v-on:option:deselected="() => changeProducts(0)"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching && search.length > 3">
                        {{ $t("copyProducts.noProductsFoundFor") }}
                        <em>{{ search }}</em
                        >.
                      </template>
                      <span v-else>{{
                        $t("copyProducts.startTypingToSearchForAProduct")
                      }}</span>
                    </template>
                    <template v-slot:selected-option="option">
                      <div class="row">
                        <div class="col-auto px-0">
                          <img
                            :src="option?.image"
                            v-if="
                              option?.image !== null &&
                              option?.image !== undefined
                            "
                            class="product-image"
                            alt=""
                          />
                          <BaseIcon
                            v-else
                            name="no-image"
                            class="product-image"
                          />
                        </div>
                        <div class="col">
                          <div>
                            <b>{{ option.name }}</b>
                          </div>
                          <div>
                            <span
                              v-for="char in option.caracteristics"
                              :key="char"
                              class="me-2"
                            >
                              {{ char }}
                            </span>
                            <span class="me-2">
                              {{ option.serial }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-slot:option="option">
                      <span class="me-2">
                        {{ option.serial }}
                      </span>
                      <span
                        v-for="char in option.caracteristics"
                        :key="char"
                        class="me-2"
                      >
                        {{ char }}
                      </span>
                      <b>{{ option.name }}</b>
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="col-12" v-if="showDescription">
                <label class="form-label bold-12 text-gray">{{
                  $t("meta.description")
                }}</label>
                <textarea
                  v-model="p.description"
                  class="form-control"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row w-100">
            <div class="col-6">
              <label class="cstm-check d-flex" for="ct-1">
                <span class="regular-12 text-black mt-3">
                  {{ $t("newManualIncoming.addDescription") }}
                </span>
                <input type="checkbox" v-model="showDescription" id="ct-1" />
                <span class="checkmark mt-3"></span>
              </label>
            </div>
            <div class="col-6 text-end">
              <button
                type="button"
                class="btn btn-primary"
                v-on:click="addIncome"
                :class="{ 'loading-spin': loadAddBtn }"
                :disabled="loadAddBtn"
              >
                {{ $t("finance.save") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../modules/http";
import { useStore } from "vuex";
import barcodeRead from "../../modules/barcodeRead";
import BaseIcon from "../icons/BaseIcon";
const bootstrap = require("bootstrap");

export default {
  name: "NewOutgoing",
  props: {
    modelValue: Object,
    show: Boolean,
    id: {
      default() {
        return null;
      },
    },
  },
  emits: ["update:modelValue", "update:show", "newItem"],
  data() {
    return {
      store: useStore(),
      myModal: null,
      success: false,
      showDescription: false,
      lastSearchId: 0,
      loadAddBtn: false,
      products: [],
      addProducts: [{ id: null, quantity: 1, products: [] }],
      productType: 1,
      newOutcome: {
        warehouse_id: this.warehouse,
        type: 1,
      },
    };
  },
  computed: {
    warehouses() {
      return this.store.state.warehouses;
    },
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
    selectedWarehouse() {
      return this.warehouses.find((e) => e.id === this.warehouse);
    },
    productTypeOptions() {
      if (this.selectedWarehouse?.type === 3) {
        return [{ name: "Product", id: 1 }];
      }

      if (this.selectedWarehouse?.type === 2) {
        return [{ name: "Material", id: 2 }];
      }

      return [
        { name: "Product", id: 1 },
        { name: "Material", id: 2 },
      ];
    },
  },
  mounted() {
    this.newOutcome.warehouse_id = this.warehouse;
    this.myModal = new bootstrap.Modal(this.$refs.modal, {
      backdrop: "static",
    });
    if (this.show) {
      this.myModal.show();
    } else {
      this.myModal.hide();
    }
    if (this.selectedWarehouse?.type === 2) {
      this.productType = 2;
    } else if (this.selectedWarehouse?.type === 3) {
      this.productType = 1;
    }
  },
  watch: {
    show() {
      if (this.show) {
        this.myModal.show();
        if (this.id === null) {
          barcodeRead.load(this.barcodeReaded);
        }
      } else {
        this.myModal.hide();
        if (this.id === null) {
          barcodeRead.unload();
        }
      }
    },
    warehouse() {
      this.newOutcome.warehouse_id = this.warehouse;
      if (this.selectedWarehouse.type === 2) {
        this.productType = 2;
      } else if (this.selectedWarehouse.type === 3) {
        this.productType = 1;
      }
    },
  },
  methods: {
    barcodeReaded(code) {
      console.log(code);
      http
        .fetch(
          `/products/${
            this.productType === 2 ? "materials" : "products"
          }/search`,
          { q: code }
        )
        .then((data) => {
          if (data.length > 0) {
            this.addProducts[0].products = data;
            this.addProducts[0].id = data[0].variant_id;
            this.changeProducts(data[0].variant_id);
          }
        });
    },
    changeProducts(variant_id) {
      let hasNull = false;
      let quantity = 0;
      let count = 0;
      for (const p in this.addProducts) {
        if (this.addProducts[p].id === null) {
          if (p !== 0) {
            this.addProducts.splice(p, 1);
          } else {
            hasNull = true;
          }
        } else if (this.addProducts[p].id === variant_id) {
          count++;
          quantity += this.addProducts[p].quantity;
          if (count > 1) {
            this.addProducts.splice(p, 1);
          }
        }
      }
      if (count > 1) {
        for (const p in this.addProducts) {
          if (this.addProducts[p].id === variant_id) {
            this.addProducts[p].quantity = quantity;
            break;
          }
        }
      }
      if (!hasNull) {
        this.addProducts.unshift({ id: null, quantity: 1, products: [] });
      }
    },
    addIncome() {
      this.loadAddBtn = true;
      let incoming =
        this.id === null ? Object.assign({}, this.newOutcome) : { id: this.id };
      incoming.items = [];

      for (const product of this.addProducts) {
        if (product.id !== null && product.id !== undefined) {
          incoming.items.unshift({
            id: product.id,
            quantity: product.quantity,
            description: product?.description ?? null,
          });
        }
      }

      http
        .fetch(
          this.id === null
            ? "/warehouses/outgoing"
            : "/warehouses/outgoing/add-items",
          incoming,
          true
        )
        .then((data) => {
          this.$emit("newItem", data);
          this.myModal.hide();
          this.$emit("update:show", false);
          this.loadAddBtn = false;
          this.addProducts = [{ id: null, quantity: 1, products: [] }];
        })
        .catch(() => {
          this.loadAddBtn = false;
        });
    },
    fetchProducts(search, loading, p) {
      if (search.length < 3) {
        return;
      }
      loading(true);
      let lastSearchId = Math.random();
      this.lastSearchId = lastSearchId;
      http
        .fetch(
          `/products/${
            this.productType === 2 ? "materials" : "products"
          }/search`,
          { q: search }
        )
        .then((data) => {
          if (this.lastSearchId === lastSearchId) {
            p.products = data;
            loading(false);
          }
        })
        .catch(() => {
          loading(false);
        });
    },
  },
  components: { BaseIcon },
};
</script>
